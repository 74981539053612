import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { notification } from "uikit";
import axios from "axios";
import { get, isEmpty } from "lodash";
import GenericLoader from "../generic_loader";
import { Image } from "../../types/common";
import ImageSorter from "../image_sorter";
const styles = require("./styles.module.scss");

type Props = {
  images: Image[];
  setImages: (images: Image[]) => void;
  uploadUrl: string;
  deleteUrl: string;
  maxImages?: number;
}

const removeImage = ({ id, setImages, setLoading, deleteUrl }) => {
  setLoading(true);
  axios
    .delete(`${deleteUrl}/${id}`)
    .then((res) => {
      setLoading(false);
      setImages(get(res, "data.data"));
    })
    .catch((error) => {
      setLoading(false);
      notification({
        message: `<span uk-icon=\"icon: warning\"></span> ${get(
          error,
          "response.data.error"
        )}`,
        pos: "top-center",
        status: "danger",
      });
    });
};

const ImageUploader = ({ images, setImages, uploadUrl, deleteUrl, maxImages = 5 }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    for (let file of acceptedFiles) {
      const fileName = file.name;
      const fd = new FormData();
      fd.append("file", file);
      fd.append("multiple", "true");

      setLoading(true);

      axios
        .post(uploadUrl, fd, {
          headers: { "X-Requested-With": "XMLHttpRequest", "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setLoading(false);
          setImages(get(res, "data.data"));
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    }
  }, []);

  const BYTE_PER_MB = 4194304;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({ onDrop, maxFiles: maxImages, maxSize: BYTE_PER_MB * maxImages });
  // const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;
  // {isFileTooLarge && (
  //   <div className="inline-error">
  //     File is too large.
  //   </div>
  // )}
  const renderUploadArea = () => {
    if (images.length <= maxImages) {
      return (
        <div className={styles.uploaderWrapper} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div>Drop the files here ...</div>
          ) : (
            <>
              <p>
                {`Drag 'n' drop some files here, or click here to select files
                (max ${maxImages} images).`}
              </p>
              <p>
                {`Support image up to ${maxImages}MB in size and the following formats: JPG,
                PNG and GIF.`}
              </p>
            </>
          )}
        </div>
      );
    }
    return (
      <div className={styles.uploaderWrapper} {...getRootProps()}>
        <input {...getInputProps()} />
        <p>{`Max ${maxImages} images allowed.`}</p>
      </div>
    );
  };

  return (
    <>
      {!isEmpty(images) && (
        <ImageSorter images={images} setImages={setImages} removeImage={(id) =>
          removeImage({
            id,
            setImages,
            deleteUrl,
            setLoading,
          })}/>
      )}
      {loading && <GenericLoader />}
      {renderUploadArea()}
    </>
  );
};

export default ImageUploader;
