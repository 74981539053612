import React, { useState } from "react";
import mount from "../../../lib/mount";
const styles = require("./styles.module.scss");

export const BlogSearch = () => {
  const [query, setQuery] = useState<string>(null);

  const onChange = (value: string) => {
    setQuery(value);
  }

  const onSubmit = (key: string) => {
    if (key === 'Enter') {
      window.location.assign(`/blogs?query=${query}`)
    }
  }

  return (
    <div className={styles.wrapper}>
      <input
        autoComplete="off"
        className="uk-input"
        type="text"
        placeholder="Search blog"
        onChange={(e) => onChange(e.target.value)}
        value={query}
        onKeyDown={(e) => onSubmit(e.key)}
      />
    </div>
  )
}

mount(BlogSearch, "blog-search");
