import React, { useState, useEffect } from "react";
import axios from "axios";
import { get, isEmpty } from "lodash";
import { notification } from "uikit";
import GenericLoader from "../../generic_loader";

interface Props extends React.HTMLProps<JSX.Element> {
  searchUrl?: string;
  searchBoxPlaceholder?: string;
  setData: (data: any) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const SearchBox = ({
  searchUrl,
  searchBoxPlaceholder,
  setData,
  loading,
  setLoading,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const onSearch = () => {
    setLoading(true);
    axios
      .get(`${searchUrl}${searchTerm}`)
      .then((res) => {
        setLoading(false);
        setData(get(res, "data.data"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> Unexpected error occurred, please contact support`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  if (!searchUrl) {
    return null;
  }

  return (
    <div className="uk-flex">
      <input
        className="uk-input"
        type="text"
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={searchBoxPlaceholder}
      />
      <button
        disabled={loading}
        onClick={onSearch}
        className="uk-button uk-button-default"
      >
        Search
      </button>
    </div>
  );
};

export default SearchBox;
