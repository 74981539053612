import React, {useState} from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import ImageSorterItem from '../image_sorter_item';
const styles = require("./styles.module.scss");

function ImageSorter({ images, setImages, removeImage }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {activationConstraint: {distance: 10}}),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const {active, over} = event;
    if (active.id !== over.id) {
      setImages((imgs) => {
        const oldIndex = imgs.findIndex(i => i.id === active.id);
        const newIndex = imgs.findIndex(i => i.id === over.id);
        return arrayMove(imgs, oldIndex, newIndex);
      });
    }
  }
  

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={images}
        strategy={horizontalListSortingStrategy}
      >
        <p>Drag the images to reorder (from left to right). Click on <b>Save & Continue</b> after reordering</p>
        <div className={styles.imgsWrapper}>
          {images.map(image => <ImageSorterItem key={`img-${image.id}`} id={image.id}>
            <div className={styles.imgWrapper}>
              <img src={image.url} width={300} />
              <span
                className={styles.imgIcon}
                onClick={()=>removeImage(image.id)}
                uk-icon="icon: trash; ratio: 1.5"
              ></span>
            </div>
          </ImageSorterItem>)}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export default ImageSorter;
