import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../lib/mount";
import GenericLoader from "../generic_loader";

interface IFormInputs {
  name: string;
  email: string;
  phone: string;
  message?: string;
}

const QuickEnquiryForm = () => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axios
      .post("/api/quick_enquiries", {
        enquiry: { ...data },
      })
      .then((_) => {
        setLoading(false);
        setSuccess(true);
        notification({
          message: '<span uk-icon="icon: check"></span> Enquiry sent!',
          pos: "top-center",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <form>
      <h5 className="uk-modal-title">Make an enquiry</h5>
      <div>
        {loading && <GenericLoader />}
        <div className="uk-margin">
          <input
            ref={register({ required: "This is required" })}
            className="uk-input"
            type="text"
            name="name"
            placeholder="Enter your name"
          />
          {errors.name && (
            <span className="inline-error">{errors.name.message}</span>
          )}
        </div>
        <div className="uk-margin">
          <input
            ref={register({ required: "This is required" })}
            className="uk-input"
            type="text"
            name="email"
            placeholder="Enter your email"
          />
          {errors.email && (
            <span className="inline-error">{errors.email.message}</span>
          )}
        </div>
        <div className="uk-margin">
          <input
            ref={register({ required: "This is required" })}
            className="uk-input"
            type="number"
            name="phone"
            placeholder="Enter phone NO."
          />
          {errors.phone && (
            <span className="inline-error">{errors.phone.message}</span>
          )}
        </div>
        <div className="uk-margin">
          <textarea
            ref={register}
            className="uk-textarea"
            name="message"
            placeholder="Enter message"
          />
          {errors.message && (
            <span className="inline-error">{errors.message.message}</span>
          )}
        </div>
      </div>
      <div className="uk-modal-footer uk-text-center uk-flex">
        <button
          className="uk-button uk-button-default uk-margin-small-right"
          type="button"
          onClick={() => window.history.back()}
        >
          {success ? "Done" : "Cancel"}
        </button>
        {!success && (
          <button
            className="uk-button uk-button-primary"
            type="submit"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Enquire
          </button>
        )}
      </div>
    </form>
  );
};

export default QuickEnquiryForm;

mount(QuickEnquiryForm, "quick-enquiry-form");
