import React, { useState, useEffect } from "react";
import axios from "axios";
import { get, isEmpty } from "lodash";
import mount from "../../lib/mount";
import GenericLoader from "../generic_loader";
import ActionGroup from "./action_group";
import DeleteButton from "../delete_button";

interface Props extends React.HTMLProps<JSX.Element> {
  tableHeaders: string[];
  fetchDataUrl: string;
  searchUrl?: string;
  searchBoxPlaceholder?: string;
}

const DataTable = ({
  fetchDataUrl,
  tableHeaders,
  searchUrl,
  searchBoxPlaceholder,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(fetchDataUrl)
      .then((res) => {
        setLoading(false);
        setData(get(res, "data.data"));
      })
      .catch((error) => {
        setLoading(false);
        console.log(`${fetchDataUrl} returns ${error}`);
      });
  }, []);

  const renderTableHeaders = () => {
    return (
      <thead>
        <tr>
          {tableHeaders.map((th, i) => (
            <th key={`th-${i}`}>{th}</th>
          ))}
        </tr>
      </thead>
    );
  };

  const renderActionButtonGroup = (actions, i) => {
    return actions.map((action) => {
      if (action.action === "delete") {
        return (
          <td key={`td-${action.id}`}>
            <DeleteButton
              name={action.name}
              id={action.id}
              url={action.url}
              ukIcon={action.ukIcon}
              onSuccessAction={() =>
                setData(data.filter((_, index) => index !== i))
              }
            />
          </td>
        );
      }
      return (
        <td key={`td-${action.id}`}>
          <a href={action.url} uk-icon={action.ukIcon} id={action.id}>
            {action.name}
          </a>
        </td>
      );
    });
  };

  const renderTableBody = () => {
    if (isEmpty(data)) {
      return (
        <tbody>
          <tr>
            <td className="uk-text-center" colSpan={tableHeaders.length}>
              No data available
            </td>
          </tr>
        </tbody>
      );
    }
    return (
      <tbody>
        {data.map((tr, i) => {
          return (
            <tr key={`tr-${i}`}>
              {tr.map((td, j) => {
                // actions cell
                if (td?.actions) {
                  return renderActionButtonGroup(td.actions, i);
                }
                return (
                  <td
                    key={`tr-${i}-td-${j}`}
                    dangerouslySetInnerHTML={{ __html: td }}
                  ></td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  if (loading) {
    return <GenericLoader />;
  }

  return (
    <div>
      <ActionGroup
        setData={setData}
        searchUrl={searchUrl}
        searchBoxPlaceholder={searchBoxPlaceholder}
      />
      <table className="uk-table uk-table-responsive table-hover uk-table-striped">
        {renderTableHeaders()}
        {renderTableBody()}
      </table>
    </div>
  );
};

export default DataTable;

mount(DataTable, "data-table");
