import React, { useState, useEffect } from "react";
import axios from "axios";
import { get, omit } from "lodash";
import mount from "../../../lib/mount";
import GenericBarChart from "../../charts/bar_chart";
import GenericLoader from "../../generic_loader";
import InlineDateRangePicker from "../../inline_date_range_picker";
import { notification } from "uikit";

const styles = require("./styles.module.scss");

interface Total {
  name: string;
  stats: string;
  subtitle?: string;
  subtitleIcon?: string;
}
const defaultStartDate = new Date(
  new Date().setDate(new Date().getDate() - 31)
);
const defaultEndDate = new Date(new Date().setDate(new Date().getDate() - 1));

const ListingDashboard = () => {
  const [totals, setTotals] = useState([]);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  useEffect(() => {
    onSearch();
  }, []);

  const renderSubtitleIcon = (subtitleIcon?: string) => {
    if (subtitleIcon) {
      return <span uk-icon={subtitleIcon}></span>;
    }
    return null;
  };

  const renderTotal = (total: Total) => {
    const { name, stats, subtitle, subtitleIcon } = total;
    return (
      <div
        key={`Total ${name}`}
        className={`uk-card uk-card-default uk-card-body uk-flex-middle ${styles.totalCard}`}
      >
        <div>{name}</div>
        <div className={styles.stats}>{stats}</div>
        {subtitle && (
          <div className={styles.subtitle}>
            {renderSubtitleIcon(subtitleIcon)}
            {subtitle}
          </div>
        )}
      </div>
    );
  };

  const renderTotals = () => {
    return (
      <div className="uk-flex uk-flex-center uk-flex-wrap">
        {totals.map((total) => {
          return renderTotal(total);
        })}
      </div>
    );
  };

  const renderUpgradeHistory = (listing) => {
    return (
      <div
        className={`uk-card uk-card-default uk-card-body ${styles.metaCard}`}
      >
        {listing.upgrades.map((upgrade) => {
          return (
            <dl className="uk-description-list">
              <dt>Ugrade</dt>
              <dd>{upgrade.display_name}</dd>
              <dt>Expiry date</dt>
              <dd>{upgrade.upgraded_to}</dd>
            </dl>
          );
        })}
      </div>
    );
  };

  const renderListing = (listing) => {
    return (
      <div
        className={`uk-flex uk-flex-wrap-reverse uk-flex-center ${styles.listingCard}`}
      >
        <div
          className={`uk-card uk-card-default uk-card-body ${styles.chartCard}`}
        >
          <h4 className="uk-heading-xxsmall">{`Listing #${listing.number}`}</h4>
          <GenericBarChart
            barName="Engagements"
            data={omit(listing, [
              "number",
              "days_on_market",
              "upgrades",
              "price",
              "upgrade_link",
            ])}
          />
        </div>
        <div className="uk-flex-column">
          <div
            className={`uk-card uk-card-default uk-card-body ${styles.metaCard}`}
          >
            <dl className="uk-description-list">
              {listing.upgrade_link && (
                <>
                  <dt>Upgrade has expired</dt>
                  <dd>
                    <a
                      className={styles.upgradeLink}
                      href={listing.upgrade_link}
                    >
                      Click here to upgrade
                    </a>
                  </dd>
                </>
              )}
              <dt>Listing #</dt>
              <dd>{listing.number}</dd>
              <dt>Days on market</dt>
              <dd>{listing.days_on_market}</dd>
              <dt>Price</dt>
              <dd>{listing.price}</dd>
            </dl>
          </div>
          {renderUpgradeHistory(listing)}
        </div>
      </div>
    );
  };

  const renderListings = () => {
    if (listings.length > 0) {
      return (
        <div className="uk-flex uk-flex-column">
          {listings.map((listing) => {
            return renderListing(listing);
          })}
        </div>
      );
    }
    return null;
  };

  const onSearch = () => {
    setLoading(true);
    axios
      .get(
        `/api/me/dashboard?start_date=${startDate
          .toISOString()
          .slice(0, 10)}&end_date=${endDate.toISOString().slice(0, 10)}`
      )
      .then((resp) => {
        setLoading(false);
        setTotals(get(resp, "data.totals", []));
        setListings(get(resp, "data.listing_data", []));
      })
      .catch(() => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> Cannot process your request, please try again later`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <div className="uk-container">
      <div className="uk-flex uk-flex-between uk-flex-wrap">
        <h1 className={`uk-heading-xsmall ${styles.h1}`}>Dashboard</h1>
        <div className={`uk-flex uk-flex-middle ${styles.dpWrapper}`}>
          <InlineDateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <button
            className="uk-button uk-button-primary"
            type="submit"
            disabled={loading}
            onClick={onSearch}
          >
            Search
          </button>
        </div>
      </div>
      <h4 className={`uk-heading-xxsmall ${styles.h4}`}>
        Please note the data presented is not real-time, there could be some
        delay in the report
      </h4>
      <hr />
      <div className="uk-flex uk-flex-column uk-flex-center">
        {loading && <GenericLoader />}
        {renderTotals()}
        <hr />
        {renderListings()}
      </div>
    </div>
  );
};

export default ListingDashboard;

mount(ListingDashboard, "listing-dashboard");
