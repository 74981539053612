import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";
import { Listing } from "../../../types/common";

interface IFormInputs {
  title: string;
  description: string;
}

interface Props extends React.HTMLProps<JSX.Element> {
  listing: Listing;
  upgradeTypeId: number;
  step: string;
}

const BasicListingInfoForm = ({ listing, upgradeTypeId, step }: Props) => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [submissionError, setSubmissionError] = useState(null);
  const [loading, setLoading] = useState(false);

  const axiosPromise = (data: IFormInputs) => {
    if (listing.id) {
      return axios.patch(`/private_listings/${listing.id}`, {
        ...data,
        step,
      });
    }
    return axios.post(`/private_listings`, {
      ...data,
      step,
    });
  };

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axiosPromise(data)
      .then((resp) => {
        notification({
          message: '<span uk-icon="icon: check"></span> Listing saved',
          pos: "top-center",
          status: "success",
        });
        window.location.assign(get(resp, "data.data.url"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <div className="uk-container-xsmall uk-margin-auto">
      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h4 className="uk-text-center">Basic listing info</h4>
        </div>
        <form>
          <div className="uk-card-body">
            {loading && <GenericLoader />}
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="text"
                name="title"
                placeholder="Enter title"
                defaultValue={listing.title}
              />
              {errors.title && (
                <span className="inline-error">{errors.title.message}</span>
              )}
            </div>
            <div className="uk-margin">
              <textarea
                ref={register({ required: "This is required" })}
                className="uk-textarea"
                name="description"
                placeholder="Enter description"
                defaultValue={listing.description}
                rows={5}
              />
              {errors.description && (
                <span className="inline-error">
                  {errors.description.message}
                </span>
              )}
            </div>
          </div>
          <div className="uk-card-footer uk-text-center">
            <button
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
              className="uk-button uk-button-primary"
            >
              Save & continue
            </button>
            {submissionError && (
              <span className="inline-error">{submissionError}</span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BasicListingInfoForm;

mount(BasicListingInfoForm, "basic-listing-info-form");
