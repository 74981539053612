import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { injectStripe, CardElement } from "react-stripe-elements";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";
import { Listing } from "../../../types/common";

interface Props extends React.HTMLProps<JSX.Element> {
  listing: Listing;
  clientSecret: string;
  paymentIntentId: string;
  stripe: any;
  elements: any;
  upgradeName: string;
  upgradeCost: string;
}

const StripeForm = ({
  listing,
  clientSecret,
  paymentIntentId,
  stripe,
  elements,
  upgradeName,
  upgradeCost,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const axiosPromise = () => {
    setLoading(true);
    return axios.post(`/private_listings/${listing.id}/payment`, {
      payment_intent_id: paymentIntentId,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement("card"),
        },
      })
      .then((resp) => {
        const stripeError = get(resp, "error.message");
        const stripeStatus = get(resp, "paymentIntent.status");
        setLoading(false);
        if (stripeError) {
          notification({
            message: `<span uk-icon=\"icon: warning\"></span> ${stripeError}`,
            pos: "top-center",
            status: "danger",
          });
        } else if (stripeStatus === "succeeded") {
          axiosPromise()
            .then((resp) => {
              notification({
                message: '<span uk-icon="icon: check"></span> Listing saved',
                pos: "top-center",
                status: "success",
              });
              window.location.assign(get(resp, "data.data.url"));
            })
            .catch((error) => {
              setLoading(false);
              notification({
                message: `<span uk-icon=\"icon: warning\"></span> Payment confirmation failed. Please contact admin@anybusiness.com.au`,
                pos: "top-center",
                status: "danger",
              });
            });
        }
      });
  };

  return (
    <div className="uk-flex uk-flex-wrap uk-flex-center">
      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h4 className="uk-text-center">{`${upgradeName}: $${upgradeCost}`}</h4>
        </div>
        <div className="uk-card-body">
          <form>
            <CardElement
              hidePostalCode
              style={{ base: { fontSize: "22px" } }}
            />
            {loading && <GenericLoader />}
            <div className="pl-option-select-btn">
              <button
                className="uk-button-primary"
                disabled={loading}
                onClick={onSubmit}
              >
                Pay
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default injectStripe(StripeForm);
