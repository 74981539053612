import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const minDate = new Date(new Date().setDate(new Date().getDate() - 90));
const maxDate = new Date();

const InlineDateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props) => {
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default InlineDateRangePicker;
