import axios from "axios";
import { get } from "lodash";

interface Option {
  value: any;
  label: string;
}

interface FetchChildCategoryProps {
  setLoading: (isLoading: boolean) => void;
  setSuccess: (data: Option[]) => void;
  parentCatId: number;
}

export const fetchChildCategories = ({
  parentCatId,
  setLoading,
  setSuccess,
}: FetchChildCategoryProps) => {
  setLoading(true);
  axios
    .get(
      `/categories/get_sub_categories.json?listing%5Bparent_category_id%5D=${parentCatId}`
    )
    .then((resp) => {
      setSuccess(get(resp, "data.data"));
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
    });
};
