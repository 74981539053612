import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";

interface IFormInputs {
  name: string;
  email: string;
  message?: string;
  contact_number: string;
}

interface Props extends React.HTMLProps<JSX.Element> {
  agentId: string;
}

const EnquireAgent = ({ agentId }: Props) => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axios
      .post("/broker_enquiries", {
        broker_enquiry: { ...data, broker_id: agentId },
      })
      .then((_) => {
        setLoading(false);
        setSuccess(true);
        notification({
          message: '<span uk-icon="icon: check"></span> Enquiry sent!',
          pos: "top-center",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <div>
      <a
        uk-icon="icon: mail"
        className="uk-button uk-button-secondary"
        href="#agent-enquiry-modal"
        uk-toggle={`target: #agent-enquiry-modal-${agentId}`}
      >
        Enquire
      </a>
      <div
        id={`agent-enquiry-modal-${agentId}`}
        className="uk-flex-top"
        data-uk-modal
      >
        <form className="uk-modal-dialog uk-margin-auto-vertical">
          <button
            className="uk-modal-close-default"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-modal-header">
            <h4 className="uk-modal-title">Broker Enquiry</h4>
          </div>
          <div className="uk-modal-body">
            {loading && <GenericLoader />}
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="text"
                name="name"
                placeholder="Enter your name"
              />
              {errors.name && (
                <span className="inline-error">{errors.name.message}</span>
              )}
            </div>
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="text"
                name="email"
                placeholder="Enter your email"
              />
              {errors.email && (
                <span className="inline-error">{errors.email.message}</span>
              )}
            </div>
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="number"
                name="contact_number"
                placeholder="Enter phone NO."
              />
              {errors.contact_number && (
                <span className="inline-error">
                  {errors.contact_number.message}
                </span>
              )}
            </div>
            <div className="uk-margin">
              <textarea
                ref={register({ required: "This is required" })}
                className="uk-textarea"
                name="message"
                placeholder="Enter message"
              />
              {errors.message && (
                <span className="inline-error">{errors.message.message}</span>
              )}
            </div>
          </div>
          <div className="uk-modal-footer uk-text-right uk-flex">
            <button
              className="uk-button uk-button-default uk-modal-close uk-margin-small-right"
              type="button"
            >
              {success ? "Done" : "Cancel"}
            </button>
            {!success && (
              <button
                className="uk-button uk-button-primary"
                type="submit"
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
              >
                Send
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnquireAgent;

mount(EnquireAgent, "enquire-agent");
