import React, { useState } from "react";
import { formatIsoDateStr } from "../../../lib/date";
import mount from "../../../lib/mount";
import { EmailCampaign } from "../../../types/common";

type EmailCampaignsType = {
  emailCampaignProps: EmailCampaign[];
};

const EmailCampaigns = ({ emailCampaignProps }: EmailCampaignsType) => {
  const [emailCampaigns, setEmailCampaigns] = useState(emailCampaignProps);

  const onSearch = (value) => {
    if (value.length > 2) {
      setEmailCampaigns(
        emailCampaigns.filter(
          (ec) =>
            (ec.subject &&
              ec.subject.toLowerCase().includes(value.toLowerCase())) ||
            (ec.name && ec.name.toLowerCase().includes(value.toLowerCase()))
        )
      );
    } else {
      setEmailCampaigns(emailCampaignProps);
    }
  };

  return (
    <>
      <div>
        <form className="uk-search uk-search-default">
          <span uk-search-icon="true"></span>
          <input
            className="uk-search-input"
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => onSearch(e.target.value)}
          />
        </form>
        <a
          className="uk-button uk-button-primary uk-button-small"
          href="/backend/email_campaigns/new"
        >
          New campaign
        </a>
      </div>
      <div className="uk-overflow-auto">
        <table className="uk-table uk-table-hover uk-table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Scheduled </th>
              <th>Delivered</th>
              <th>Subject</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {emailCampaigns.map((ec) => {
              return (
                <tr key={ec.id}>
                  <td>{ec.name}</td>
                  <td>{ec.status}</td>
                  <td>
                    {ec.scheduleTime && formatIsoDateStr(ec.scheduleTime)}
                  </td>
                  <td>{ec.sentTime && formatIsoDateStr(ec.sentTime)}</td>
                  <td className="ellipsis" style={{ width: 100 }}>
                    {ec.subject}
                  </td>
                  <td>
                    <a
                      className="uk-button uk-button-primary uk-button-small"
                      href={`/backend/email_campaigns/${ec.id}/edit`}
                    >
                      Edit
                    </a>
                    <a className="uk-button uk-button-secondary uk-button-small">
                      Stats
                    </a>
                    {false && (
                      <a className="uk-button uk-button-default uk-button-small">
                        Delete
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmailCampaigns;

mount(EmailCampaigns, "email-campaigns");
