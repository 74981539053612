import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { get, isEmpty } from "lodash";
import { notification } from "uikit";
import GenericLoader from "../../generic_loader";
import SimilarListings from "../similar_listings";
import ViewAgentMobile from "../../agents/view_mobile";
import { Agent, ListingEnquiryFormProps } from "../../../types/common";
import { parameterize } from "../../../lib/url";
const styles = require("./styles.module.scss");

interface IFormInputs {
  name: string;
  email: string;
  phone: string;
  message?: string;
  non_disclosure_agreement?: boolean;
  inspect_business?: boolean;
  similar_businesses?: boolean;
}

const EnquiryFormContent = ({
  listingId,
  agency,
  agents,
  isMobileDevice,
}: ListingEnquiryFormProps) => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [similarListings, setSimilarListings] = useState([]);

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axios
      .post("/enquiries", {
        enquiry: { ...data, listing_id: listingId },
      })
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
        notification({
          message: '<span uk-icon="icon: check"></span> Enquiry sent!',
          pos: "top-center",
          status: "success",
        });
        setSimilarListings(get(resp, "data.data.similar_listings"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const renderAgentsAgency = (agent: Agent) => {
    return (
      <div className={styles.agentsAgencyDiv} key={`agent-${agent.agentId}`}>
        <div className={styles.agents}>{agent.name}</div>
        <ViewAgentMobile
          listingId={listingId}
          agentId={agent.agentId}
          isMobileDevice={isMobileDevice}
          defaultContactNO={agent.defaultContactNO}
        />
      </div>
    );
  };

  const who = (): string => {
    return agents && agents.map((agent) => agent.name).join(" & ");
  };

  const renderContent = () => {
    if (!isEmpty(similarListings)) {
      return (
        <>
          <div className="uk-modal-header">
            <h5 className="uk-modal-title">
              Enquiry sent! You may also be interested in...
            </h5>
          </div>
          <div className={`uk-modal-body ${styles.similarListings}`}>
            <SimilarListings listings={similarListings} />
          </div>
        </>
      );
    }

    return (
      <>
        <div className={`uk-modal-header ${styles.modalHeader}`}>
          <div className={styles.header}>
            {agents && agents.map((agent) => renderAgentsAgency(agent))}
            {agency && <div className={styles.agency}><a href={`/business-brokers/${parameterize(agency)}`}>{agency}</a></div>}
          </div>
        </div>
        <div className="uk-modal-body">
          {loading && <GenericLoader />}
          <div className="uk-margin">
            <div
              className={styles.title}
            >{`Ask ${who()} about this business`}</div>
            <textarea
              ref={register}
              className="uk-textarea"
              name="message"
              defaultValue="I would like to know more about this business."
            />
            {errors.message && (
              <span className="inline-error">{errors.message.message}</span>
            )}
          </div>
          <div className="uk-margin">
            <input
              ref={register({ required: "This is required" })}
              className="uk-input"
              type="text"
              name="name"
              placeholder="Your name"
            />
            {errors.name && (
              <span className="inline-error">{errors.name.message}</span>
            )}
          </div>
          <div className="uk-margin">
            <input
              ref={register({ required: "This is required" })}
              className="uk-input"
              type="text"
              name="email"
              placeholder="Your email"
            />
            {errors.email && (
              <span className="inline-error">{errors.email.message}</span>
            )}
          </div>
          <div className="uk-margin">
            <input
              ref={register({ required: "This is required" })}
              className="uk-input"
              type="number"
              name="phone"
              placeholder="Your phone number"
            />
            {errors.phone && (
              <span className="inline-error">{errors.phone.message}</span>
            )}
          </div>
          <div className="uk-margin">
            <label>
              <input
                ref={register}
                className="uk-checkbox"
                type="checkbox"
                name="similar_businesses"
              />
              &nbsp;Receive updates of similar businesses
            </label>
            {errors.similar_businesses && (
              <span className="inline-error">
                {errors.similar_businesses.message}
              </span>
            )}
          </div>
          <div className={`${styles.enquiryBtn} uk-text-center`}>
            {renderEnquiryButton()}
          </div>
        </div>
        <div className="uk-modal-footer uk-text-center">
          Your informtion is protected by our{" "}
          <a
            href="https://www.anybusiness.com.au/contents/privacy_policy"
            className={styles.privacyLink}
          >
            Privacy Policy. Click here for more information.
          </a>
        </div>
      </>
    );
  };

  const renderEnquiryButton = () => {
    if (success) {
      return <h5 className={styles.enquirySent}>Enquiry sent!</h5>;
    }
    return (
      <button
        className="uk-button uk-button-primary"
        type="submit"
        disabled={loading}
        onClick={handleSubmit(onSubmit)}
      >
        Send enquiry
      </button>
    );
  };

  return <>{renderContent()}</>;
};

export default EnquiryFormContent;
