import React, { useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../lib/mount";
import GenericLoader from "../generic_loader";

interface Props extends React.HTMLProps<JSX.Element> {
  name: string;
  id: string;
  ukIcon?: string;
  onSuccessUrl?: string;
  url: string;
  onSuccessAction?: () => void;
}

const DeleteButton = ({
  name,
  id,
  ukIcon,
  onSuccessUrl,
  url,
  onSuccessAction,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    axios
      .delete(url)
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
        notification({
          message: `<span uk-icon="icon: check"></span> ${name} deleted!`,
          pos: "top-center",
          status: "success",
        });
        if (onSuccessUrl) {
          window.location.assign(onSuccessUrl);
        }
        if (onSuccessAction) {
          onSuccessAction();
        }
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const renderDeleteButton = () => {
    if (ukIcon) {
      return (
        <a
          href="#delete-confirmation-modal"
          title="Delete"
          uk-icon={ukIcon}
          uk-toggle={`target: #delete-confirmation-modal-${id}`}
        ></a>
      );
    }
    return (
      <a
        className="uk-button uk-button-secondary uk-button-yellow"
        href="#delete-confirmation-modal"
        uk-toggle={`target: #delete-confirmation-modal-${id}`}
      >
        Delete
      </a>
    );
  };

  return (
    <div>
      {renderDeleteButton()}
      <div
        id={`delete-confirmation-modal-${id}`}
        className="uk-flex-top"
        data-uk-modal
      >
        <form className="uk-modal-dialog uk-margin-auto-vertical">
          <button
            className="uk-modal-close-default"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-modal-header">
            <h4 className="uk-modal-title">
              {success ? `${name} deleted` : "Are you sure?"}
            </h4>
          </div>
          <div className="uk-modal-footer uk-text-right">
            <button
              className="uk-button uk-button-default uk-modal-close uk-margin-small-right"
              type="button"
            >
              {success ? "Done" : "No"}
            </button>
            {loading && <GenericLoader />}
            {!success && (
              <button
                className="uk-button uk-button-primary"
                type="submit"
                disabled={loading}
                onClick={onSubmit}
              >
                Yes
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteButton;

mount(DeleteButton, "delete-button");
