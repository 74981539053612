import React from "react";
import mount from "../../../lib/mount";
import SortSearch from "../sort_search";
import SaveSearch from "../save_search";

const styles = require("./styles.module.scss");

const SortAndSave = () => {
  return (
    <div className={styles.wrapper}>
      <SortSearch />
      <SaveSearch />
    </div>
  );
};

export default SortAndSave;

mount(SortAndSave, "sort-and-save");
