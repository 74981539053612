import React, { useState, useEffect } from "react";
import axios from "axios";
import SuburbAutoComplete from "../../suburb_autocomplete";
import LocationStateSelect from "../../location_state_select";
import RegionSelect from "../../region_select";
import mount from "../../../lib/mount";
import { agencySearchURL } from "../../../lib/search";
import GenericLoader from "../../generic_loader";
const styles = require("./styles.module.scss");

interface NumberOption {
  value: number;
  label: string;
}

interface StringOption {
  value: string;
  label: string;
}

interface FormData extends React.HTMLProps<JSX.Element> {
  formData: {
    keywords?: string;
    defaultSuburbOptions?: StringOption[];
    selectedSuburbs?: string[];
    locationState?: number;
    defaultLocationState?: NumberOption;
    selectedRegions?: number[];
    defaultRegionOptions?: NumberOption[];
  };
}

export const AgencySideSearch = ({ formData }: FormData) => {
  const [suburbs, setSuburbs] = useState([
    { label: "MELBOURNE, VIC 3000", value: "MELBOURNE, VIC 3000" },
    { label: "SYDNEY, NSW 2000", value: "SYDNEY, NSW 2000" },
    { label: "BRISBANE, QLD 4000", value: "BRISBANE, QLD 4000" },
    { label: "ADELAIDE, SA 5000", value: "ADELAIDE, SA 5000" },
    { label: "PERTH, WA 6000", value: "PERTH, WA 6000" },
    { label: "HOBART, TAS 7000", value: "HOBART, TAS 7000" },
    { label: "DARWIN, NT 0800", value: "DARWIN, NT 0800" },
    { label: "CANBERRA, ACT 2600", value: "CANBERRA, ACT 2600" },
  ]);
  const [loading, setLoading] = useState(false);
  const [selectedSuburbs, setSelectedSuburbs] = useState(
    formData.selectedSuburbs
  );
  const [keywords, setKeywords] = useState(formData.keywords);
  const [locationState, setLocationState] = useState(formData.locationState);
  const [selectedRegions, setSelectedRegions] = useState(
    formData.selectedRegions
  );

  useEffect(() => {
    axios
      .get("/api/suburbs?hint=value")
      .then((res) => {
        setSuburbs(res.data.suburbs);
      })
      .catch((error) => {
        console.log(`api/suburbs returns ${error}`);
      });
  }, []);

  const search = (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    e.preventDefault();
    window.location.assign(
      agencySearchURL({
        selectedSuburbs,
        keywords,
        locationState,
        selectedRegions,
      })
    );
  };

  return (
    <form className={styles.formWrapper}>
      <div className={`uk-flex-column ${styles.searchForm}`}>
        <div className={`${styles.suburbFieldWrapper}`}>
          <SuburbAutoComplete
            defaultOptions={formData.defaultSuburbOptions}
            options={suburbs}
            selectOptions={setSelectedSuburbs}
          />
        </div>
        <div className={styles.searchFieldWrapper}>
          <input
            autoComplete="off"
            className="uk-input"
            type="text"
            placeholder="Agency's name"
            defaultValue={keywords}
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
          />
        </div>
        <LocationStateSelect
          defaultOption={formData.defaultLocationState}
          selectOption={setLocationState}
        />
        <div className={styles.categoryWrapper}>
          <RegionSelect
            defaultOptions={formData.defaultRegionOptions}
            locationState={locationState}
            selectOptions={setSelectedRegions}
          />
        </div>
        <div className={styles.searchFieldWrapper}>
          {loading && <GenericLoader />}
          <button onClick={search} className="uk-button uk-button-primary">
            Agency Search
          </button>
        </div>
      </div>
    </form>
  );
};

mount(AgencySideSearch, "agency-side-search");
