import React, { useState } from "react";
import { get } from "lodash";
import mount from "../../../lib/mount";
import { urlToParams } from "../../../lib/url";
import GenericLoader from "../../generic_loader";

const styles = require("./styles.module.scss");

const SortSearch = () => {
  const [loading, setLoading] = useState(false);
  const [sortValue, setSortValue] = useState(
    get(urlToParams(), "sort", "featured")
  );

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLoading(true);
    let sortUrl: string = "";
    if (window.location.href.includes("sort=")) {
      sortUrl = window.location.href.replace(
        /sort=\S+/,
        `sort=${event.target.value}`
      );
    } else if (window.location.href.includes("?")) {
      sortUrl = `${window.location.href}&sort=${event.target.value}`;
    } else {
      sortUrl = `${window.location.href}?sort=${event.target.value}`;
    }
    window.location.assign(sortUrl);
  };

  return (
    <>
      {loading && <GenericLoader />}
      <div className={`uk-flex uk-flex-middle ${styles.wrapper}`}>
        <label><b>Sort by</b>&nbsp;</label>
        <select onChange={onChange} value={sortValue} className={`${styles.select} uk-select`}>
          <option value="featured">Featured</option>
          <option value="price-high-low">Price(High - Low)</option>
          <option value="price-low-high">Price(Low - High)</option>
          <option value="date-new-old">Date(Newest - Oldest)</option>
          <option value="date-old-new">Date(Oldest - Newest)</option>
        </select>
      </div>
    </>
  );
};

export default SortSearch;

mount(SortSearch, "sort-search");
