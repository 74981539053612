import React from "react";
import mount from "../../../lib/mount";
import { ListingEnquiryFormProps } from "../../../types/common";
import EnquiryFormContent from "../enquiry_form_content";
const styles = require("./styles.module.scss");

const ListingEnquiryInlineForm = ({
  listingId,
  agency,
  agents,
  isMobileDevice,
}: ListingEnquiryFormProps) => {
  return (
    <form data-html2canvas-ignore="true">
      <h5 className={`${styles.header} uk-modal-title`}>Make an enquiry</h5>
      <EnquiryFormContent
        listingId={listingId}
        isMobileDevice={isMobileDevice}
        agents={agents}
        agency={agency}
      />
    </form>
  );
};

export default ListingEnquiryInlineForm;

mount(ListingEnquiryInlineForm, "listing-enquiry-inline-form");
