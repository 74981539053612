import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import Select from "react-select";
import mount from "../../../lib/mount";
import { fetchChildCategories } from "../../../lib/categories";
import {
  customStyles,
  json2Options,
  string2Option,
  array2Options,
  findOptionFromJsonByValue,
} from "../../../lib/form";
import GenericLoader from "../../generic_loader";
import { Option, SelectListProps, Listing } from "../../../types/common";
import SingleSuburbAutoComplete from "../../single_suburb_autocomplete";

interface Props extends React.HTMLProps<JSX.Element> {
  listing: Listing;
  step: string;
  availableStatuses: string[];
  availableParentCategoryOptions: Option[];
  defaultParentCategoryOption1: Option;
  defaultChildCategoryOption1: Option;
  defaultParentCategoryOption2: Option;
  defaultChildCategoryOption2: Option;
  defaultParentCategoryOption3: Option;
  defaultChildCategoryOption3: Option;
  availableAddressDisplayOptions: string[];
  availablePriceDisplayOptions: object;
  suburbHint?: string;
}

interface IFormInputs {
  street_name: string;
  price: number;
}

const ListingDetailsForm = ({
  listing,
  step,
  availableStatuses,
  availableParentCategoryOptions,
  defaultParentCategoryOption1,
  defaultChildCategoryOption1,
  defaultParentCategoryOption2,
  defaultChildCategoryOption2,
  defaultParentCategoryOption3,
  defaultChildCategoryOption3,
  availableAddressDisplayOptions,
  availablePriceDisplayOptions,
  suburbHint,
}: Props) => {
  const { handleSubmit, register, errors } = useForm();
  const [submissionError, setSubmissionError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(listing.status);

  const [parentCatId1, setParentCatId1] = useState(listing.parent_category_id);
  const [childCatId1, setChildCatId1] = useState(listing.child_category_id);
  const [childCategoryOptions1, setChildCategoryOptions1] = useState([]);

  const [parentCat1Error, setParentCat1Error] = useState(null);

  const [parentCatId2, setParentCatId2] = useState(listing.parent_category2_id);
  const [childCatId2, setChildCatId2] = useState(listing.child_category2_id);
  const [childCategoryOptions2, setChildCategoryOptions2] = useState([]);

  const [parentCatId3, setParentCatId3] = useState(listing.parent_category3_id);
  const [childCatId3, setChildCatId3] = useState(listing.child_category3_id);
  const [childCategoryOptions3, setChildCategoryOptions3] = useState([]);

  const [suburbError, setSuburbError] = useState(null);
  const [suburb, setSuburb] = useState(suburbHint);
  const [suburbs, setSuburbs] = useState([]);

  const [addressDisplayOptionsError, setAddressDisplayOptionsError] = useState(
    null
  );
  const [addressDisplayOption, setAddressDisplayOption] = useState(
    listing.address_display_options
  );
  const [priceDisplayOption, setPriceDisplayOption] = useState(
    listing.price_display_options
  );
  const [priceDisplayOptionsError, setPriceDisplayOptionsError] = useState(
    null
  );

  useEffect(() => {
    axios
      .get("/api/suburbs?hint=value&all=true")
      .then((res) => {
        setSuburbs(res.data.suburbs);
      })
      .catch((error) => {
        console.log(`api/suburbs returns ${error}`);
      });
  }, []);

  const onSubmit = (data: IFormInputs) => {
    if (!parentCatId1) {
      setParentCat1Error("This is required");
      return;
    }
    if (!suburb) {
      setSuburbError("This is required");
      return;
    }
    setLoading(true);
    axios
      .patch(`/private_listings/${listing.id}`, {
        step,
        status,
        parent_category_id: parentCatId1,
        child_category_id: childCatId1,
        parent_category2_id: parentCatId2,
        child_category2_id: childCatId2,
        parent_category3_id: parentCatId3,
        child_category3_id: childCatId3,
        street_name: data.street_name,
        suburb_hint: suburb,
        address_display_options: addressDisplayOption,
        price: data.price,
        price_display_options: priceDisplayOption,
      })
      .then((resp) => {
        notification({
          message: '<span uk-icon="icon: check"></span> Listing updated',
          pos: "top-center",
          status: "success",
        });
        window.location.assign(get(resp, "data.data.url"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const onStatusChange = (selectedOpt: Option) => {
    setStatus(selectedOpt.value);
  };

  const renderStatus = () => {
    if (!listing.status) {
      return null;
    }
    return (
      <div className="uk-margin uk-grid uk-flex-middle">
        <label className="uk-width-1-4">Status</label>
        <Select
          className="uk-width-expand@m"
          styles={customStyles}
          defaultValue={string2Option(listing.status)}
          isSearchable={false}
          onChange={onStatusChange}
          options={array2Options(availableStatuses)}
        />
      </div>
    );
  };

  const onCat1Change = (selectedOpt: Option): void => {
    setParentCat1Error(null);
    setParentCatId1(selectedOpt.value);
    setChildCatId1(null);
    fetchChildCategories({
      setLoading,
      parentCatId: selectedOpt.value,
      setSuccess: setChildCategoryOptions1,
    });
  };

  const onChildCat1Change = (selectedOpt: Option): void => {
    setChildCatId1(selectedOpt.value);
  };

  const onCat2Change = (selectedOpt: Option): void => {
    setParentCatId2(selectedOpt.value);
    setChildCatId2(null);
    fetchChildCategories({
      setLoading,
      parentCatId: selectedOpt.value,
      setSuccess: setChildCategoryOptions2,
    });
  };

  const onChildCat2Change = (selectedOpt: Option): void => {
    setChildCatId2(selectedOpt.value);
  };

  const onCat3Change = (selectedOpt: Option): void => {
    setParentCatId3(selectedOpt.value);
    setChildCatId3(null);
    fetchChildCategories({
      setLoading,
      parentCatId: selectedOpt.value,
      setSuccess: setChildCategoryOptions3,
    });
  };

  const onChildCat3Change = (selectedOpt: Option): void => {
    setChildCatId3(selectedOpt.value);
  };

  const selectSuburb = (selectedOpt: Option): void => {
    setSuburb(selectedOpt.value);
    setSuburbError(null);
  };

  const selectAddressDisplayOption = (selectedOpt: Option): void => {
    setAddressDisplayOption(selectedOpt.value);
    setAddressDisplayOptionsError(null);
  };

  const selectPriceDisplayOption = (selectedOpt: Option): void => {
    setPriceDisplayOption(selectedOpt.value);
    setPriceDisplayOptionsError(null);
  };

  const renderCategorySection = ({
    label,
    defaultOption,
    selectOption,
    options,
  }: SelectListProps) => {
    return (
      <div className="uk-margin uk-grid uk-flex-middle">
        <label className="uk-width-1-4@m">{label}</label>
        <Select
          className="uk-width-expand@m"
          styles={customStyles}
          defaultValue={defaultOption}
          onChange={selectOption}
          options={options}
        />
      </div>
    );
  };

  const renderLocationSection = () => {
    return (
      <>
        <div className="uk-margin uk-grid uk-flex-middle">
          <label className="uk-width-1-4@m">Street *</label>
          <div className="uk-width-expand@m">
            <input
              ref={register({ required: "This is required" })}
              className="uk-input"
              type="text"
              name="street_name"
              defaultValue={listing.street_name}
              placeholder="Enter street NO and name"
            />
            {errors.street_name && (
              <span className="inline-error">{errors.street_name.message}</span>
            )}
          </div>
        </div>
        <div className="uk-margin uk-grid uk-flex-middle">
          <label className="uk-width-1-4@m">Suburb *</label>
          <div className="uk-width-expand@m">
            <SingleSuburbAutoComplete
              selectOption={selectSuburb}
              options={suburbs}
              defaultOption={string2Option(suburbHint)}
            />
            {suburbError && <span className="inline-error">{suburbError}</span>}
          </div>
        </div>
        <div className="uk-margin uk-grid uk-flex-middle">
          <label className="uk-width-1-4@m">Display *</label>
          <Select
            className="uk-width-expand@m"
            styles={customStyles}
            defaultValue={string2Option(listing.address_display_options)}
            onChange={selectAddressDisplayOption}
            options={array2Options(availableAddressDisplayOptions)}
          />
          {addressDisplayOptionsError && (
            <span className="inline-error">{addressDisplayOptionsError}</span>
          )}
        </div>
      </>
    );
  };

  const renderPriceSection = () => {
    return (
      <>
        <div className="uk-margin uk-grid uk-flex-middle">
          <label className="uk-width-1-4@m">Price *</label>
          <div className="uk-width-expand@m">
            <input
              ref={register({ required: "This is required" })}
              className="uk-input"
              type="number"
              name="price"
              defaultValue={listing.price}
              placeholder="Enter listing price (Number only)"
            />
            {errors.price && (
              <span className="inline-error">{errors.price.message}</span>
            )}
            <label>$ or comma are not allowed</label>
          </div>
        </div>
        <div className="uk-margin uk-grid uk-flex-middle">
          <label className="uk-width-1-4@m">Display *</label>
          <Select
            className="uk-width-expand@m"
            styles={customStyles}
            defaultValue={findOptionFromJsonByValue(
              priceDisplayOption,
              availablePriceDisplayOptions
            )}
            onChange={selectPriceDisplayOption}
            options={json2Options(availablePriceDisplayOptions)}
          />
          {priceDisplayOptionsError && (
            <span className="inline-error">{priceDisplayOptionsError}</span>
          )}
        </div>
      </>
    );
  };

  const renderSections = () => {
    return (
      <ul uk-accordion="multiple: true">
        <li className="uk-open">
          <a className="uk-accordion-title" href="#">
            Categories
          </a>
          <div className="uk-accordion-content">
            {renderCategorySection({
              label: "Category #1",
              defaultOption: defaultParentCategoryOption1,
              selectOption: onCat1Change,
              options: availableParentCategoryOptions,
            })}
            {parentCat1Error && (
              <span className="inline-error">{parentCat1Error}</span>
            )}
            {renderCategorySection({
              label: "Subcategory",
              defaultOption: defaultChildCategoryOption1,
              selectOption: onChildCat1Change,
              options: childCategoryOptions1,
            })}
            {renderCategorySection({
              label: "Category #2",
              defaultOption: defaultParentCategoryOption2,
              selectOption: onCat2Change,
              options: availableParentCategoryOptions,
            })}
            {renderCategorySection({
              label: "Subcategory",
              defaultOption: defaultChildCategoryOption2,
              selectOption: onChildCat2Change,
              options: childCategoryOptions2,
            })}
            {renderCategorySection({
              label: "Category #3",
              defaultOption: defaultParentCategoryOption3,
              selectOption: onCat3Change,
              options: availableParentCategoryOptions,
            })}
            {renderCategorySection({
              label: "Subcategory",
              defaultOption: defaultChildCategoryOption3,
              selectOption: onChildCat3Change,
              options: childCategoryOptions3,
            })}
          </div>
          <hr />
        </li>
        <li className="uk-open">
          <a className="uk-accordion-title" href="#">
            Location
          </a>
          <div className="uk-accordion-content">{renderLocationSection()}</div>
          <hr />
        </li>
        <li className="uk-open">
          <a className="uk-accordion-title" href="#">
            Price
          </a>
          <div className="uk-accordion-content">{renderPriceSection()}</div>
        </li>
      </ul>
    );
  };

  return (
    <div className="uk-container-xxsmall uk-margin-auto">
      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h4 className="uk-text-center">Listing Details</h4>
        </div>
        <form>
          <div className="uk-card-body">
            {renderStatus()}
            {renderSections()}
            {loading && <GenericLoader center={false} />}
          </div>
          <div className="uk-card-footer uk-text-center">
            <button
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
              className="uk-button uk-button-primary"
            >
              Save & continue
            </button>
            {submissionError && (
              <span className="inline-error">{submissionError}</span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ListingDetailsForm;

mount(ListingDetailsForm, "listing-details-form");
