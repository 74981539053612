import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";

const styles = require("./styles.module.scss");

interface IFormInputs {
  name: string;
  email?: string;
  frequency: string;
}

const SaveSearch = () => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axios
      .post("/search/save", {
        save_search: { ...data, query: window.location.pathname + window.location.search },
      })
      .then((_) => {
        setLoading(false);
        setSuccess(true);
        notification({
          message: '<span uk-icon="icon: check"></span> Search saved!',
          pos: "top-center",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <div>
      <a
        className={`uk-flex uk-flex-middle uk-button uk-button-default ellipsis ${styles.wrapper}`}
        href="#save-search-modal"
        uk-toggle="target: #save-search-modal"
      >
        Save search<span uk-icon="icon: bookmark"></span>
      </a>
      <div id="save-search-modal" className="uk-flex-top" data-uk-modal>
        <form className="uk-modal-dialog uk-margin-auto-vertical">
          <button
            className="uk-modal-close-default"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-modal-header">
            <h4 className="uk-modal-title">Save search</h4>
          </div>
          <div className="uk-modal-body">
            {loading && <GenericLoader />}
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="text"
                name="name"
                placeholder="Name your search"
              />
              {errors.name && (
                <span className="inline-error">{errors.name.message}</span>
              )}
            </div>
            <div className="uk-margin">
              <label>Receive emails about similar listings?</label>
              <select name="frequency" className="uk-select" ref={register}>
                <option>Never</option>
                <option>Daily</option>
                <option>Weekly</option>
                <option>Fortnightly</option>
                <option>Monthly</option>
              </select>
            </div>
            <div className="uk-margin">
              <input
                ref={register}
                className="uk-input"
                type="text"
                name="email"
                placeholder="Enter email"
              />
              {errors.email && (
                <span className="inline-error">{errors.email.message}</span>
              )}
            </div>
            <div className="uk-margin">
              <a href="/bookmarks">Click here to manage your saved searches</a>
            </div>
          </div>
          <div className="uk-modal-footer uk-text-right uk-flex">
            <button
              className="uk-button uk-button-default uk-modal-close"
              type="button"
            >
              {success ? "Done" : "Cancel"}
            </button>
            {!success && (
              <button
                className="uk-button uk-button-primary"
                type="submit"
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveSearch;

mount(SaveSearch, "save-search");
