import React, { useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";
import { ViewAgentMobileProps } from "../../../types/common";
const styles = require("./styles.module.scss");

const ViewAgentMobile = ({
  listingId,
  agentId,
  isMobileDevice,
  defaultContactNO,
}: ViewAgentMobileProps) => {
  const [loading, setLoading] = useState(false);
  const [contactNO, setContactNO] = useState(null);

  const track = () => {
    axios.post("/api/metrics", {
      metric_type: "phone_reveal",
      listing_id: listingId,
    });
  };

  const onClick = () => {
    if (!agentId && defaultContactNO) {
      track();
      return setContactNO(defaultContactNO);
    }
    setLoading(true);
    axios
      .get(`/agents/${agentId}/show_mobile`, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
        params: { listing_id: listingId },
      })
      .then((resp) => {
        setLoading(false);
        setContactNO(get(resp, "data.mobile_no"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const onClickMobile = () => {
    if (defaultContactNO) {
      track();
    }
    window.location.assign(`tel:${defaultContactNO}`);
  };

  if (isMobileDevice && defaultContactNO) {
    return (
      <div className={styles.callBtnDiv}>
        <a
          uk-icon="icon: receiver"
          className={`uk-button uk-button-default uk-flex-center ${styles.customViewBtn}`}
          onClick={onClickMobile}
        >
          Call
        </a>
      </div>
    );
  }

  if (contactNO) {
    return (
      <div className={`uk-button uk-button-default ${styles.customViewBtn}`}>
        {contactNO}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {loading && <GenericLoader />}
      <a
        uk-icon="icon: arrow-right"
        className={`uk-button uk-button-default ${styles.customViewBtn}`}
        onClick={onClick}
      >
        View mobile
      </a>
    </div>
  );
};

export default ViewAgentMobile;

mount(ViewAgentMobile, "view-agent-mobile-btn");
