import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";
import { Listing } from "../../../types/common";

interface Props extends React.HTMLProps<JSX.Element> {
  listing: Listing;
  step: string;
  viewListingUrl?: string;
}

const ReviewForm = ({ step, listing, viewListingUrl }: Props) => {
  const [loading, setLoading] = useState(false);

  const axiosPromise = () => {
    return axios.patch(`/private_listings/${listing.id}`, {
      step,
    });
  };

  const onSubmit = () => {
    setLoading(true);
    axiosPromise()
      .then((resp) => {
        notification({
          message: '<span uk-icon="icon: check"></span> Listing saved',
          pos: "top-center",
          status: "success",
        });
        window.location.assign(get(resp, "data.data.url"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const viewListing = () => {
    window.location.assign(viewListingUrl);
  }

  if (viewListingUrl) {
    return (
      <div className="pl-option-wrapper uk-flex uk-flex-wrap">
        <div className="pl-option-select-btn">
          {loading && <GenericLoader center={false} />}
          <button onClick={viewListing} className="uk-button-primary">
            View listing
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="pl-option-wrapper uk-flex uk-flex-wrap">
      <div className="pl-option-select-btn">
        {loading && <GenericLoader center={false} />}
        <button onClick={onSubmit} className="uk-button-primary">
          Proceed to payment
        </button>
      </div>
    </div>
  );
};

export default ReviewForm;

mount(ReviewForm, "review-form");
