import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";
import { Listing } from "../../../types/common";

interface IFormInputs {
  upgrade_type_id: number;
}

interface Props extends React.HTMLProps<JSX.Element> {
  listing: Listing;
  defaultUpgradeTypeId: number;
  private90Id: number;
  private180Id: number;
  step: string;
}

const PackageForm = ({
  step,
  listing,
  defaultUpgradeTypeId,
  private90Id,
  private180Id,
}: Props) => {
  const { register, handleSubmit } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);

  const axiosPromise = (data: IFormInputs) => {
    return axios.post(`/private_listings/${listing.id}/package`, {
      ...data,
      step,
    });
  };

  const onSubmit = (data: IFormInputs) => {
    if (!data?.upgrade_type_id) {
      return notification({
        message: `<span uk-icon=\"icon: warning\"></span>Please select a package`,
        pos: "top-center",
        status: "danger",
      });
    }
    setLoading(true);
    axiosPromise(data)
      .then((resp) => {
        notification({
          message: '<span uk-icon="icon: check"></span> Listing saved',
          pos: "top-center",
          status: "success",
        });
        window.location.assign(get(resp, "data.data.url"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <div className="pl-option-wrapper uk-flex uk-flex-wrap">
      <div className="pl-option-row-alt">
        <div className="pl-option-space"></div>
        <div className="pl-option-header pl-option">
          <h4>
            <input
              className="uk-button-primary"
              defaultChecked={defaultUpgradeTypeId === private90Id}
              name="upgrade_type_id"
              type="radio"
              value={private90Id}
              ref={register()}
            />
            90 Days
          </h4>
          <div>Advertise your business for 3 months</div>
          <div className="pl-option-price">$250</div>
          <div className="pl-option-gst">inc GST</div>
          <div className="pl-option-features-mobile-wrapper">
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>Unlimited edits</div>
            </div>
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>No commission</div>
            </div>
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>No extra fees</div>
            </div>
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>All leads are sent directly to your email</div>
            </div>
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>
                Assistance uploading your business listing by our experienced
                staff
              </div>
            </div>
          </div>
        </div>
        <div className="pl-option-spacer"></div>
        <div className="pl-option-header pl-option">
          <h4>
            <input
              className="uk-button-primary"
              defaultChecked={defaultUpgradeTypeId === private180Id}
              name="upgrade_type_id"
              type="radio"
              value={private180Id}
              ref={register()}
            />
            180 Days
          </h4>
          <div>Advertise your business for 6 months</div>
          <div className="pl-option-price">$450</div>
          <div className="pl-option-gst">inc GST</div>
          <div className="pl-option-features-mobile-wrapper">
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>Unlimited edits</div>
            </div>
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>No commission</div>
            </div>
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>No extra fees</div>
            </div>
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>All leads are sent directly to your email</div>
            </div>
            <div className="pl-option-features-mobile-row">
              <span uk-icon="icon: check;"></span>
              <div>
                Assistance uploading your business listing by our experienced
                staff
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-option-features-wrapper">
        <div className="pl-option-row">
          <div className="pl-option-space">Unlimited edits</div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
          <div className="pl-option-spacer"></div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
        </div>
        <div className="pl-option-row-alt">
          <div className="pl-option-space">No commission</div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
          <div className="pl-option-spacer"></div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
        </div>
        <div className="pl-option-row">
          <div className="pl-option-space">No extra fees</div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
          <div className="pl-option-spacer"></div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
        </div>
        <div className="pl-option-row-alt">
          <div className="pl-option-space">
            All leads are sent directly to your email
          </div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
          <div className="pl-option-spacer"></div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
        </div>
        <div className="pl-option-row">
          <div className="pl-option-space">
            Assistance uploading your business listing by our experienced staff
          </div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
          <div className="pl-option-spacer"></div>
          <div className="pl-option">
            <span uk-icon="icon: check;"></span>
          </div>
        </div>
      </div>
      <div className="pl-option-select-btn">
        {loading && <GenericLoader center={false} />}
        <button onClick={handleSubmit(onSubmit)} className="uk-button-primary">
          Save and continue
        </button>
      </div>
    </div>
  );
};

export default PackageForm;

mount(PackageForm, "package-form");
