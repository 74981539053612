import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import Select from "react-select";
import mount from "../../lib/mount";
import {
  customStyles,
  string2Option,
  array2Options,
} from "../../lib/form";
import GenericLoader from "../generic_loader";
import { Option } from "../../types/common";
import SuburbAutoComplete from "../suburb_autocomplete";
import CategorySelect from "../category_select";
const styles = require("./styles.module.scss");

interface Props extends React.HTMLProps<JSX.Element> {
  availableFrequencies: string[];
}

interface IFormInputs {
  email: string;
}

const Subscription = ({ availableFrequencies }: Props) => {
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [selectedSuburbs, setSelectedSuburbs] = useState([]);
  const [suburbs, setSuburbs] = useState([]);
  const [frequency, setFrequency] = useState(availableFrequencies[1]);

  useEffect(() => {
    axios
      .get("/api/suburbs?hint=value")
      .then((res) => {
        setSuburbs(res.data.suburbs);
      })
      .catch((error) => {
        console.log(`api/suburbs returns ${error}`);
      });
  }, []);

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axios
      .post(`/subscriptions`, {
        subscription: {
          child_category_ids: selectedCategories,
          suburb_hint: selectedSuburbs,
          frequency,
          ...data,
        },
      })
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
        notification({
          message:
            '<span uk-icon="icon: check"></span> Subscribed successfully',
          pos: "top-center",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const onFrequencyChange = (selectedOpt: Option) => {
    setFrequency(selectedOpt.value);
  };

  const renderEmail = () => {
    return (
      <div className="uk-margin uk-grid uk-flex-middle">
        <label className="uk-width-1-5">Email</label>
        <div className={`uk-width-expand@m ${styles.email}`}>
          <input
            ref={register({ required: "This is required" })}
            className="uk-input"
            type="text"
            name="email"
            placeholder="Enter email"
          />
          {errors.email && (
            <span className="inline-error">{errors.email.message}</span>
          )}
        </div>
      </div>
    );
  };

  const renderFrequency = () => {
    return (
      <div className="uk-margin uk-grid uk-flex-middle">
        <label className="uk-width-1-5">Frequency</label>
        <Select
          className="uk-width-expand@m"
          styles={customStyles}
          defaultValue={string2Option(frequency)}
          isSearchable={false}
          onChange={onFrequencyChange}
          options={array2Options(availableFrequencies)}
        />
      </div>
    );
  };

  const renderSuburbs = () => {
    return (
      <div className="uk-margin uk-grid uk-flex-middle">
        <label className="uk-width-1-5">Suburbs</label>
        <div className="uk-width-expand@m">
          <SuburbAutoComplete
            options={suburbs}
            selectOptions={setSelectedSuburbs}
          />
        </div>
      </div>
    );
  };

  const renderCategories = () => {
    return (
      <div className="uk-margin uk-grid uk-flex-middle">
        <label className="uk-width-1-5">Categories</label>
        <div className={`uk-width-expand@m ${styles.categoryWrapper}`}>
          <CategorySelect selectOptions={setSelectedCategories} />
        </div>
      </div>
    );
  };

  const renderButton = () => {
    if (success) {
      return (
        <a className="uk-button uk-button-primary" href="/email_alerts">
          Manage your subscriptions
        </a>
      );
    }
    return (
      <>
        <button
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-primary"
        >
          Submit
        </button>
      </>
    );
  };

  return (
    <div className="uk-container-xxsmall uk-margin-auto">
      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h4 className="uk-text-center">Subscribe to newsletter</h4>
        </div>
        <form>
          <div className="uk-card-body">
            {renderEmail()}
            {renderCategories()}
            {renderFrequency()}
            {renderSuburbs()}
            {loading && <GenericLoader />}
          </div>
          <div className="uk-card-footer uk-text-center">{renderButton()}</div>
        </form>
      </div>
    </div>
  );
};

export default Subscription;

mount(Subscription, "subscription-form");
