import React, { useState } from "react";
import CategorySelect from "../../category_select";
import PriceSelect from "../../price_select";
import LocationStateSelect from "../../location_state_select";
import RegionSelect from "../../region_select";
import mount from "../../../lib/mount";
import { listingSearchURL, seoPagination } from "../../../lib/search";
import GenericLoader from "../../generic_loader";
import KeywordAutocomplete from "../../keyword_autocomplete";
import { StringOption, NumberOption, Option } from "../../../types/common";
import SuburbAutocompleteAsync from "../../suburb_autocomplete_async";

const styles = require("./styles.module.scss");

interface FormData extends React.HTMLProps<JSX.Element> {
  formData: {
    keywords?: string;
    minPrice?: string;
    maxPrice?: string;
    defaultSuburbOptions?: StringOption[];
    selectedSuburbs?: string[];
    defaultCatOptions?: NumberOption[];
    selectedCategories?: Option[];
    locationState?: Option;
    defaultLocationState?: NumberOption;
    selectedRegions?: Option[];
    defaultRegionOptions?: NumberOption[];
    newListing?: boolean;
    status?: string;
  };
}

export const ListingSideSearch = ({ formData }: FormData) => {
  seoPagination();
  const [loading, setLoading] = useState(false);
  const [selectedSuburbs, setSelectedSuburbs] = useState(
    formData.selectedSuburbs
  );
  const [selectedCategories, setSelectedCategories] = useState(
    formData.selectedCategories
  );
  const [keywords, setKeywords] = useState(formData.keywords);
  const [minPrice, setMinPrice] = useState(formData.minPrice);
  const [maxPrice, setMaxPrice] = useState(formData.maxPrice);
  const [locationState, setLocationState] = useState(formData.locationState);
  const [selectedRegions, setSelectedRegions] = useState(
    formData.selectedRegions
  );
  const [newListing, setNewListing] = useState(formData.newListing || false);
  const [status, setStatus] = useState(formData.status);

  const search = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    window.location.assign(
      listingSearchURL({
        selectedSuburbs,
        keywords,
        minPrice,
        maxPrice,
        selectedCategories,
        locationState,
        selectedRegions,
        newListing,
        status,
      })
    );
  };

  return (
    <form className={styles.formWrapper}>
      <div className={`uk-flex-column ${styles.searchForm}`}>
        <div className={`${styles.suburbFieldWrapper}`}>
          <SuburbAutocompleteAsync
            defaultOptions={formData.defaultSuburbOptions}
            selectOptions={setSelectedSuburbs}
          />
        </div>
        <div className={styles.searchFieldWrapper}>
          <KeywordAutocomplete keywords={keywords} setKeywords={setKeywords} />
        </div>
        <div className={styles.categoryWrapper}>
          <CategorySelect
            defaultOptions={formData.defaultCatOptions}
            selectOptions={setSelectedCategories}
          />
        </div>
        <LocationStateSelect
          defaultOption={formData.defaultLocationState}
          selectOption={setLocationState}
        />
        <div className={styles.categoryWrapper}>
          <RegionSelect
            defaultOptions={formData.defaultRegionOptions}
            locationState={locationState?.value}
            selectOptions={setSelectedRegions}
          />
        </div>
        <PriceSelect
          defaultOption={{ label: minPrice, value: minPrice }}
          prefix="min"
          selectOption={setMinPrice}
        />
        <PriceSelect
          defaultOption={{ label: maxPrice, value: maxPrice }}
          prefix="max"
          selectOption={setMaxPrice}
        />
        <div className={`uk-flex uk-flex-wrap ${styles.checkboxGroup}`}>
          <label><input className="uk-checkbox uk-margin-small-right" type="checkbox" checked={!!newListing} onChange={()=>{setNewListing(!newListing)}}/>New listings only</label>
        </div>
        <div className={`uk-flex uk-flex-wrap ${styles.checkboxGroup}`}>
          <label><input className="uk-checkbox uk-margin-small-right" type="checkbox" checked={status === "sold"} onChange={()=>{setStatus(status === "sold" ? null : "sold")}}/>Sold listings</label>
        </div>
        <div className={styles.searchFieldWrapper}>
          {loading && <GenericLoader />}
          <button onClick={search} className="uk-button uk-button-primary">
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

mount(ListingSideSearch, "listing-side-search");
