import React, { useState, useEffect } from "react";
import axios from "axios";
import { get, isEmpty } from "lodash";
import { notification } from "uikit";
import GenericLoader from "../../generic_loader";
import SearchBox from "../search_box";

interface Props extends React.HTMLProps<JSX.Element> {
  searchUrl?: string;
  searchBoxPlaceholder?: string;
  setData: (data: any) => void;
}

const ActionGroup = ({ searchUrl, searchBoxPlaceholder, setData }: Props) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="uk-flex uk-flex-wrap">
      {loading && <GenericLoader />}
      <SearchBox
        searchUrl={searchUrl}
        searchBoxPlaceholder={searchBoxPlaceholder}
        setData={setData}
        setLoading={setLoading}
        loading={loading}
      />
    </div>
  );
};

export default ActionGroup;
