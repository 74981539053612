import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import mount from "../../../lib/mount";
import { transformData, COLORS, RADIAN } from "../../../lib/chart";
const styles = require("./styles.module.scss");
import { TransformDataType } from "../../../types/common";

type Props = TransformDataType & React.HTMLProps<JSX.Element>;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const GenericBarChart = ({
  data,
  limit,
  order,
  orderBy,
  barName = "value",
}: Props) => {
  const transformed = transformData({ data, limit, order, orderBy });
  return (
    <div className={styles.container}>
      <ResponsiveContainer>
        <BarChart
          data={transformed}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar name={barName} dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GenericBarChart;

mount(GenericBarChart, "generic-bar-chart");
