import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";
import { Listing, User } from "../../../types/common";

interface IFormInputs {
  email: string;
  first_name: string;
  password?: string;
  mobile: string;
}

interface Props extends React.HTMLProps<JSX.Element> {
  listing: Listing;
  user: User;
  step: string;
}

const UserForm = ({ step, listing, user }: Props) => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);

  const axiosPromise = (data: IFormInputs) => {
    return axios.post(`/private_listings/${listing.id}/user`, {
      ...data,
      step,
    });
  };

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axiosPromise(data)
      .then((resp) => {
        notification({
          message: '<span uk-icon="icon: check"></span> Listing saved',
          pos: "top-center",
          status: "success",
        });
        window.location.assign(get(resp, "data.data.url"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <div className="uk-container-xxsmall uk-margin-auto">
      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h4 className="uk-text-center">Your details</h4>
        </div>
        <form>
          <div className="uk-card-body">
            {loading && <GenericLoader />}
            <div className="uk-margin uk-grid uk-flex-middle">
              <label className="uk-width-1-4@m">Name *</label>
              <div className="uk-width-expand@m">
                <input
                  ref={register({ required: "This is required" })}
                  className="uk-input"
                  type="text"
                  name="first_name"
                  defaultValue={user.first_name}
                />
                {errors.first_name && (
                  <span className="inline-error">
                    {errors.first_name.message}
                  </span>
                )}
              </div>
            </div>
            <div className="uk-margin uk-grid uk-flex-middle">
              <label className="uk-width-1-4@m">Email *</label>
              <div className="uk-width-expand@m">
                <input
                  ref={register({ required: "This is required" })}
                  className="uk-input"
                  type="text"
                  name="email"
                  defaultValue={user.email}
                  placeholder="Enquiries will be sent to this email"
                />
                {errors.email && (
                  <span className="inline-error">{errors.email.message}</span>
                )}
              </div>
            </div>
            <div className="uk-margin uk-grid uk-flex-middle">
              <label className="uk-width-1-4@m">Mobile *</label>
              <div className="uk-width-expand@m">
                <input
                  ref={register({ required: "This is required" })}
                  className="uk-input"
                  type="text"
                  name="mobile"
                  defaultValue={user.mobile}
                />
                {errors.mobile && (
                  <span className="inline-error">{errors.mobile.message}</span>
                )}
              </div>
            </div>
            {!!!listing.status && (
              <div className="uk-margin uk-grid uk-flex-middle">
                <label className="uk-width-1-4@m">Password *</label>
                <div className="uk-width-expand@m">
                  <input
                    ref={register({ required: "This is required" })}
                    className="uk-input"
                    type="password"
                    name="password"
                    defaultValue={user.password}
                  />
                  {errors.password && (
                    <span className="inline-error">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="uk-card-footer uk-text-center">
            <button
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
              className="uk-button uk-button-primary"
            >
              Save & continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;

mount(UserForm, "user-form");
