import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useForm } from "react-hook-form";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";

interface IFormInputs {
  email: string;
}

interface Props extends React.HTMLProps<JSX.Element> {
  listingId: string;
  iconButton: boolean;
}

const cookies = new Cookies();

const AlertListing = ({ listingId, iconButton }: Props) => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axios
      .post(`/listings/${listingId}/listing_update_alerts`, {
        listing_update_alert: { ...data },
      })
      .then((resp) => {
        cookies.set(`listingAlert${listingId}`, get(resp, "data.data.id"), {
          path: "/",
          maxAge: 7776000,
        });
        setLoading(false);
        setSuccess(true);
        notification({
          message: '<span uk-icon="icon: check"></span> Alert saved!',
          pos: "top-center",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const unalert = () => {
    const alertId = cookies.get(`listingAlert${listingId}`);
    setLoading(true);
    axios
      .delete(`/listings/${listingId}/listing_update_alerts/${alertId}`)
      .then((_) => {
        cookies.remove(`listingAlert${listingId}`, { path: "/" });
        setLoading(false);
        notification({
          message: '<span uk-icon="icon: check"></span> Alert removed!',
          pos: "top-center",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const renderAlertButton = () => {
    if (cookies.get(`listingAlert${listingId}`)) {
      if (iconButton) {
        return (
          <a
            className="uk-icon-bell-fill uk-preserve"
            href="#listing-alert-modal"
            title="Alert"
            onClick={unalert}
          ></a>
        );
      }
      return (
        <a className="uk-button uk-button-default uk-flex-center" onClick={unalert}>
          Unalert
        </a>
      );
    }
    if (iconButton) {
      return (
        <a
          href="#listing-alert-modal"
          title="Alert"
          uk-icon="icon: bell"
          uk-toggle={`target: #listing-alert-modal-${listingId}`}
        ></a>
      );
    }
    return (
      <a
        className="uk-button uk-button-secondary uk-flex-center"
        href="#listing-alert-modal"
        uk-toggle={`target: #listing-alert-modal-${listingId}`}
        uk-icon="icon: bell"
      >
        Alert me
      </a>
    );
  };

  return (
    <div>
      {renderAlertButton()}
      <div
        id={`listing-alert-modal-${listingId}`}
        className="uk-flex-top"
        data-uk-modal
      >
        <form className="uk-modal-dialog uk-margin-auto-vertical">
          <button
            className="uk-modal-close-default"
            type="button"
            data-uk-close
          ></button>
          <div className="uk-modal-header">
            <h4 className="uk-modal-title">Alert me on price update</h4>
          </div>
          <div className="uk-modal-body">
            {loading && <GenericLoader />}
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="text"
                name="email"
                placeholder="Enter your email"
              />
              {errors.email && (
                <span className="inline-error">{errors.email.message}</span>
              )}
            </div>
          </div>
          <div className="uk-modal-footer uk-text-right uk-flex">
            <button
              className="uk-button uk-button-default uk-modal-close uk-margin-small-right"
              type="button"
              title="Save listing"
            >
              {success ? "Done" : "Cancel"}
            </button>
            {!success && (
              <button
                className="uk-button uk-button-primary"
                type="submit"
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AlertListing;

mount(AlertListing, "alert-listing");
