import React, { useState } from "react";
import { mapKeys, snakeCase } from "lodash";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import Select from "react-select";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";
import { Option, EmailCampaign } from "../../../types/common";
import { formatIsoDateStr } from "../../../lib/date";

interface Props extends React.HTMLProps<JSX.Element> {
  emailCampaign?: EmailCampaign;
  availableRecipientOptions: Option[];
  availableStatuses: Option[];
}

type IFormInputs = {
  subject: string;
  heading?: string;
  listingNumbers: string[];
} & ({ recipientFilter: string } | { emails: string[] });

const EmailCampaignForm = ({
  emailCampaign,
  availableRecipientOptions = [
    { label: "Select", value: undefined },
    { label: "1 month", value: "1 month" },
    { label: "3 months", value: "3 months" },
    { label: "6 months", value: "6 months" },
  ],
  availableStatuses = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ],
}: Props) => {
  const defaultRecipientToggle = () => {
    if (emailCampaign?.emails && emailCampaign.emails.length > 0) {
      return false;
    }
    return true;
  }
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);
  const [recipientToggle, setRecipientToggle] = useState(defaultRecipientToggle());
  const [recipientFilter, setRecipientFilter] = useState(
    emailCampaign?.recipientFilter
  );
  const [scheduleTime, setScheduleTime] = useState(emailCampaign?.scheduleTime);
  const [status, setStatus] = useState(
    emailCampaign?.status || availableStatuses[1].value
  );

  const onSubmit = (formData: IFormInputs, test: boolean) => {
    if (!formData.emails && !recipientFilter) {
      return notification({
        message: `<span uk-icon=\"icon: warning\"></span> No recipient provided`,
        pos: "top-center",
        status: "danger",
      });
    }
    setLoading(true);
    formData.recipientFilter = recipientFilter;
    formData.scheduleTime = scheduleTime;
    formData.status = status;
    formData.test = test;
    const data = mapKeys(formData, (_, k) => snakeCase(k));
    axios({
      method: emailCampaign?.id ? "PATCH" : "POST",
      url: emailCampaign?.id
        ? `/backend/email_campaigns/${emailCampaign.id}`
        : `/backend/email_campaigns`,
      data,
    })
      .then((resp) => {
        setLoading(false);
        notification({
          message: `<span uk-icon="icon: check"></span> ${get(
            resp,
            "data.message"
          )}`,
          pos: "top-center",
          status: "success",
        });
        if (get(resp, "data.id")) {
          window.location.assign("/backend/email_campaigns");
        }
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  const toggleRecipient = () => {
    setRecipientToggle(!recipientToggle);
  };

  const onRecipientFilterChange = (value) => {
    setRecipientFilter(value);
  };

  const renderRecipientPanel = () => {
    if (recipientToggle) {
      return (
        <div className="uk-margin">
          <label className="uk-width-1-4@m">Enquirers from</label>
          <Select
            name="recipientFilter"
            options={availableRecipientOptions}
            defaultValue={availableRecipientOptions.find((c) => {
              return c.value === emailCampaign?.recipientFilter;
            })}
            onChange={(val) => onRecipientFilterChange(val.value)}
          />
        </div>
      );
    }

    return (
      <div className="uk-margin">
        <label className="uk-width-1-4@m">Recipient emails</label>
        <div className="uk-width-expand@m">
          <textarea
            ref={register({ required: "This is required" })}
            className="uk-textarea"
            name="emails"
            placeholder="Enter recipient emails separated by comma e.g. abc@gmail.com,efg@gmail.com"
            defaultValue={emailCampaign?.emails.join(",")}
            rows={5}
          />
          {errors.emails && (
            <span className="inline-error">{errors.emails.message}</span>
          )}
        </div>
      </div>
    );
  };

  const renderRecipientSelect = () => {
    return (
      <>
        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
          <label>Select recipients *</label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="recipientRadio"
              onChange={toggleRecipient}
              checked={recipientToggle}
            />{" "}
            From existing enquirers
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="recipientRadio"
              onChange={toggleRecipient}
              checked={!recipientToggle}
            />{" "}
            Manual input
          </label>
        </div>

        {renderRecipientPanel()}
      </>
    );
  };

  const minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const maxDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 5);

  const onScheduleTimeChange = (value) => {
    setScheduleTime(value);
  };

  const renderSchedulePicker = () => {
    return (
      <div className="uk-margin">
        <label className="uk-width-1-4@m">Schedule</label>
        <DatePicker
          name="scheduleTime"
          placeholderText="Select date"
          onChange={(e) => onScheduleTimeChange(e)}
          selected={scheduleTime ? new Date(scheduleTime) : undefined}
          minDate={minDate}
          maxDate={maxDate}
          showTimeSelect
          dateFormat="Pp"
        />
      </div>
    );
  };

  const renderSave = () => {
    return (
      <>
        {emailCampaign?.sentTime && (
          <div>{`Campaign has been sent at ${formatIsoDateStr(
            emailCampaign.sentTime
          )}`}</div>
        )}
        <button
          disabled={loading}
          onClick={handleSubmit((formData) => onSubmit(formData, false))}
          className="uk-button uk-button-primary"
        >
          Save
        </button>
        {testEmail()}
      </>
    );
  };

  const onStatusChange = (value) => {
    setStatus(value);
  };

  const testEmail = () => {
    return (
      <button
        disabled={loading}
        onClick={handleSubmit((formData) => onSubmit(formData, true))}
        className="uk-button uk-button-secondary"
      >
        Send test email
      </button>
    );
  };

  return (
    <div className="uk-margin-auto">
      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h4 className="uk-text-center">Email Campaign Form</h4>
        </div>
        <form>
          <div className="uk-card-body">
            <div className="uk-margin">
              <label className="uk-width-1-4@m">Status *</label>
              <Select
                name="status"
                options={availableStatuses}
                defaultValue={availableStatuses.find((c) => {
                  return c.value === status;
                })}
                onChange={(val) => onStatusChange(val.value)}
              />
            </div>
            <div className="uk-margin">
              <label className="uk-width-1-4@m">Name *</label>
              <div className="uk-width-expand@m">
                <input
                  ref={register({ required: "This is required" })}
                  className="uk-input"
                  type="text"
                  name="name"
                  placeholder="Enter the name"
                  defaultValue={emailCampaign?.name}
                />
                {errors.name && (
                  <span className="inline-error">{errors.name.message}</span>
                )}
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-width-1-4@m">Subject *</label>
              <div className="uk-width-expand@m">
                <input
                  ref={register({ required: "This is required" })}
                  className="uk-input"
                  type="text"
                  name="subject"
                  placeholder="Enter the email subject"
                  defaultValue={emailCampaign?.subject}
                />
                {errors.subject && (
                  <span className="inline-error">{errors.subject.message}</span>
                )}
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-width-1-4@m">Heading (optional)</label>
              <div className="uk-width-expand@m">
                <input
                  ref={register()}
                  className="uk-input"
                  type="text"
                  name="heading"
                  placeholder="Enter heading in the email body"
                  defaultValue={emailCampaign?.heading}
                />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-width-1-4@m">Listing numbers *</label>
              <div className="uk-width-expand@m">
                <textarea
                  ref={register({ required: "This is required" })}
                  className="uk-textarea"
                  name="listingNumbers"
                  placeholder="Enter listing numbers separated by comma e.g. 123,456"
                  defaultValue={emailCampaign?.listingNumbers?.join(",")}
                  rows={5}
                />
                {errors.listingNumbers && (
                  <span className="inline-error">
                    {errors.listingNumbers.message}
                  </span>
                )}
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-width-1-4@m">Footer</label>
              <div className="uk-width-expand@m">
                <textarea
                  ref={register()}
                  className="uk-textarea"
                  name="footerContent"
                  placeholder="Content below the listings"
                  defaultValue={emailCampaign?.footerContent}
                  rows={3}
                />
              </div>
            </div>
            {renderRecipientSelect()}
            {renderSchedulePicker()}
          </div>

          <div className="uk-card-footer uk-text-center">
            {renderSave()}
            {loading && <GenericLoader center={false} />}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailCampaignForm;

mount(EmailCampaignForm, "email-campaign-form");
