import React from "react";
import mount from "../../../lib/mount";
import { ListingEnquiryFormProps } from "../../../types/common";
import EnquiryFormContent from "../enquiry_form_content";

const ListingEnquiryModal = ({
  listingId,
  agency,
  agents,
  isMobileDevice,
}: ListingEnquiryFormProps) => {
  return (
    <div>
      <a
        className="uk-button uk-button-primary uk-flex-center"
        href="#listing-enquiry-modal"
        uk-icon="icon: mail"
        id="listing-enquiry-cta"
        uk-toggle={`target: #listing-enquiry-modal-${listingId}`}
      >
        Enquire
      </a>
      <div
        id={`listing-enquiry-modal-${listingId}`}
        className="uk-flex-top"
        data-uk-modal
      >
        <form className="uk-modal-dialog uk-margin-auto-vertical">
          <button
            className="uk-modal-close-default"
            type="button"
            data-uk-close
          ></button>
          <EnquiryFormContent
            listingId={listingId}
            isMobileDevice={isMobileDevice}
            agents={agents}
            agency={agency}
          />
        </form>
      </div>
    </div>
  );
};

export default ListingEnquiryModal;

mount(ListingEnquiryModal, "listing-enquiry-modal");
