import _ from "lodash";
import { TransformDataType } from "../../types/common";

// data, e.g { deleted: 2, active: 3 }
export const transformData = ({
  data,
  limit = 5,
  order,
  orderBy,
}: TransformDataType): object[] => {
  const sorted = orderJsonByIntValue({ data, order, orderBy });
  const candidates = Object.entries(sorted);
  const sliced = limit ? candidates.slice(0, limit) : candidates;
  return sliced.reduce((memo, obj) => {
    return memo.concat({ name: obj[0], value: obj[1] });
  }, []);
};

export const orderJsonByIntValue = ({
  data,
  order = "desc",
  orderBy = 1,
}: TransformDataType): object => {
  // return Object
  //   .entries(data)
  //   .sort((a, b) => b[1] - a[1])
  //   .reduce((_sortedObj, [k,v]) => ({
  //     ..._sortedObj,
  //     [k]: v
  //  }), {});

  return _(data).toPairs().orderBy(orderBy, order).fromPairs().value();
};

export const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#C295E2",
  "#ef4b4b",
  "#11853e",
  "#751569",
  "#4ce4d0",
  "#cfec78",
  "#443a14",
  "#797977",
  "#E1306C",
  "#ad8894",
  "#0d4a25",
  "#221c52",
  "#504612",
];

export const toCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
};

export const RADIAN = Math.PI / 180;
