import React, { useState } from "react";
import Select from "react-select";
import { Option, SelectListProps } from "../../types/common";
import { customStyles } from "../../lib/form";

const SingleSuburbAutoComplete = ({
  selectOption,
  options,
  defaultOption,
}: SelectListProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onInputChange = (value: string, { action }: { action: string }) => {
    setMenuIsOpen(false);
    if (action === "input-change" && value.length > 2) {
      setMenuIsOpen(true);
    }
  };

  const onChange = (selectedOpt?: Option) => {
    selectOption(selectedOpt);
  };

  return (
    <Select
      className="uk-width-expand@m"
      styles={customStyles}
      defaultValue={defaultOption}
      menuIsOpen={menuIsOpen}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
    />
  );
};

export default SingleSuburbAutoComplete;
