import React from "react";
import { Listing } from "../../../types/common";
const styles = require("./styles.module.scss");

interface Props extends React.HTMLProps<JSX.Element> {
  listings: Listing[];
}

const SimilarListings = ({ listings }: Props) => {
  return (
    <>
      {listings.map((listing) => {
        return (
          <div
            key={`similarListings-${listing.id}`}
            className="uk-flex uk-flex-column"
          >
            <a href={listing.url}>
              <img className={styles.img} src={listing.image.url} width={300} />
            </a>
            <div className="uk-flex uk-flex-column">
              <h5>
                <a href={listing.url}>{listing.title}</a>
              </h5>
              <div className="ellipsis">{listing.price}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SimilarListings;
