import React, { useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../../lib/mount";
import GenericLoader from "../../../generic_loader";
import { Listing, Image } from "../../../../types/common";
import ImageUploader from "../../../image_uploader";

interface Props extends React.HTMLProps<JSX.Element> {
  listing: Listing;
  listingImages?: Image[];
  step: string;
}

const MediaForm = ({ listing, step, listingImages }: Props) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(listingImages || []);
  const [videoUrl, setVideoUrl] = useState<string>(listing.video_url)

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(`/admin/listings/${listing.id}`, {
        images,
        step,
        video_url: videoUrl
      })
      .then((resp) => {
        notification({
          message: '<span uk-icon="icon: check"></span> Listing saved',
          pos: "top-center",
          status: "success",
        });
        window.location.assign(get(resp, "data.data.url"));
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <div className="uk-container-large uk-margin-auto">
      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h4 className="uk-text-center">Upload images</h4>
        </div>
        <form>
          <div className="uk-card-body">
            <ImageUploader
              images={images}
              setImages={setImages}
              uploadUrl={`/admin/listings/${listing.id}/images`}
              deleteUrl={`/admin/images`}
              maxImages={20}
            />

            <h4 className="uk-text-center">Video URL</h4>
            <div className="uk-margin">
              <input
                onChange={(e) => { setVideoUrl(e.target.value) }}
                className="uk-input"
                type="text"
                name="videoUrl"
                value={videoUrl}
                placeholder="Video URL (with https) e.g. https://www.youtube.com/foo"
              />
            </div>
          </div>

          <div className="uk-card-footer uk-text-center">
            {loading && <GenericLoader center={false} />}
            <button
              disabled={loading}
              onClick={onSubmit}
              className="uk-button uk-button-primary"
            >
              Save & continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MediaForm;

mount(MediaForm, "admin-media-form");
