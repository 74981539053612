import React from "react";
import { Elements } from "react-stripe-elements";
import { StripeProvider } from "react-stripe-elements";
import StripeForm from "../stripe_form";
import mount from "../../../lib/mount";
import { Listing } from "../../../types/common";

interface Props extends React.HTMLProps<JSX.Element> {
  listing: Listing;
  clientSecret: string;
  paymentIntentId: string;
  upgradeName: string;
  upgradeCost: string;
  stripePKey: string;
}

export default function Payment({
  listing,
  clientSecret,
  paymentIntentId,
  upgradeName,
  upgradeCost,
  stripePKey,
}: Props) {
  return (
    <StripeProvider apiKey={stripePKey}>
      <Elements>
        <StripeForm
          clientSecret={clientSecret}
          paymentIntentId={paymentIntentId}
          listing={listing}
          upgradeName={upgradeName}
          upgradeCost={upgradeCost}
        />
      </Elements>
    </StripeProvider>
  );
}

mount(Payment, "payment-form");
