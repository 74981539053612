import React, { lazy, useState } from "react";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";

interface Props extends React.HTMLProps<JSX.Element> {
  listingId: string;
}

const DownloadPDFButton = ({ listingId }: Props) => {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    const htmlPDFWrapper = document.getElementById("downloadable-pdf");
    const positionInfo = htmlPDFWrapper.getBoundingClientRect();
    const HTML_Width = positionInfo.width;
    const HTML_Height = positionInfo.height;
    const topLeftMargin = 15;
    const PDF_Width = HTML_Width + topLeftMargin * 2;
    const PDF_Height = PDF_Width * 1.5 + topLeftMargin * 2;

    const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    import("html2canvas").then((html2canvas) => {
      html2canvas
        .default(document.getElementById("downloadable-pdf"), { useCORS: true })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg", 1.0);
          import("jspdf").then((module) => {
            const pdf = new module.default("p", "pt", [PDF_Width, PDF_Height]);
            pdf.addImage(
              imgData,
              "JPG",
              topLeftMargin,
              topLeftMargin,
              HTML_Width,
              HTML_Height
            );
            for (var i = 1; i <= totalPDFPages; i++) {
              pdf.addPage();
              pdf.addImage(
                imgData,
                "JPG",
                topLeftMargin,
                -(PDF_Height * i) + topLeftMargin * 4,
                HTML_Width,
                HTML_Height
              );
            }
            pdf.save(`Listing-Report-${listingId}.pdf`);
            setLoading(false);
          });
        });
    });
  };

  return (
    <>
      {loading && <GenericLoader center={false} />}
      <button
        title="Download PDF"
        className="uk-icon uk-icon-file-pdf uk-preserve"
        uk-icon="icon: file-pdf; ratio: 1.6"
        onClick={onClick}
      ></button>
    </>
  );
};

export default DownloadPDFButton;

mount(DownloadPDFButton, "download-pdf-button");
