import React, { useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { notification } from "uikit";
import mount from "../../../lib/mount";
import GenericLoader from "../../generic_loader";

interface Props extends React.HTMLProps<JSX.Element> {
  listingId: string;
  shortlisted: boolean;
}

const ShortlistListing = ({ listingId, shortlisted }: Props) => {
  const [loading, setLoading] = useState(false);
  const [localShortlisted, setLocalShortlisted] = useState(shortlisted);

  const toggle = () => {
    setLoading(true);
    axios
      .get(`/saved_businesses/${listingId}/toggle`, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      })
      .then((json) => {
        setLoading(false);
        setLocalShortlisted(!localShortlisted);
        notification({
          message: `<span uk-icon="icon: check"></span> ${get(
            json,
            "data.message"
          )}`,
          pos: "top-center",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  return (
    <div>
      {loading && <GenericLoader />}
      <button
        title="Shortlist"
        className={`uk-icon ${
          localShortlisted ? "uk-icon-star-fill uk-preserve" : null
        }`}
        onClick={toggle}
        uk-icon="icon: star; ratio: 1.6"
      ></button>
    </div>
  );
};

export default ShortlistListing;

mount(ShortlistListing, "shortlist-listing");
