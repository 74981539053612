import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import mount from "../../../lib/mount";
import { transformData, COLORS, RADIAN, toCurrency } from "../../../lib/chart";
const styles = require("./styles.module.scss");
import { TransformDataType } from "../../../types/common";

interface Props extends React.HTMLProps<JSX.Element> {
  isValue: boolean;
}

type IProps = TransformDataType & Props;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
  index,
  name,
  isValue,
  fill,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (isValue) {
    return (
      <text
        className={styles.smText}
        x={x}
        y={y}
        fill={fill}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {toCurrency(value.toFixed(1))}
      </text>
    );
  }
  return (
    <text
      className={styles.smText}
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

const GenericPieChart = ({ data, limit, order, orderBy, isValue }: IProps) => {
  const transformed = transformData({ data, limit, order, orderBy });
  return (
    <div className={styles.container}>
      <ResponsiveContainer>
        <PieChart>
          <Legend verticalAlign="top" />
          <Pie
            labelLine={true}
            label={(props) => renderCustomizedLabel({ ...props, isValue })}
            data={transformed}
            dataKey="value"
            nameKey="name"
          >
            {transformed.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GenericPieChart;

mount(GenericPieChart, "generic-pie-chart");
